/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.width-full{
  width: 100%;
  height: 100%;
}

.width-50porc {
  width: 90%;
}

.height-30rem {
  height: 40rem;
}

.margin-2rem {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.margin-10vh {
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.margin-left-5vh {
  margin-left: 5vh;
}

.bg-gray {
  background-color: lightgray;
}

.color-red {
  color: red;
}

.color-blue {
  color:blue;
}

.color-gray {
  color:gray;
}

.contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh; /* O la altura que desees */
}

.font-24px {
  font-size: 24px;
}

.font-16px {
  font-size: 16px;
}

@media (min-width: 768px) {
  /* Pantallas md y lg */
  .width-50porc {
    width: 50%;
  }
}
